@media (max-width: 991px) {
  .backOffice .content .card-con {
    grid-template-columns: repeat(2, 1fr);
  }
  .cardd-dvt-con{
    grid-template-columns: repeat(2, 1fr);

  }
  table {
    table-layout: auto;
  }

  th,
  td {
    padding: 10px;
    font-size: 14px;
  }

  table thead th {
    font-size: 15px;
  }
  header .url-con {
    display: none;
  }
  .mobile-content {
    display: block;
  }
  .mobile-content .url-con {
    /* margin-left: 50px; */
    /* width: 700px; */
    margin-top: 16px;
    position: relative;
    width: 95%;
    margin-inline: auto;
  }
  .mobile-content .url-con .url-img {
    position: absolute;
    width: 30px;
    left: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  .mobile-content .url-con input {
    outline: none;
    background: none;
    border: none;
    border: 1px solid #7b7b7c;
    border-radius: 100px;
    padding: 8px 6px;
    width: 100%;
    padding-inline-start: 12px;
    font-size: 16px;
    color: #fff;
    position: relative;
  }
  .mobile-content .url-con .copy-img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    font-size: 20px;
    color: #b38fe4;
  }
  .mobile-content .header-action-con {
    display: none;
  }
  .table-wrapper{
    margin-top: 0px;
  }
  table{
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .sidebarWidthDeafult{
    margin-left: 0px;
  }
  header {
    width: 100%;
  }
  .backOffice .content .card-con {
    grid-template-columns: repeat(1, 1fr);
  }
  .cardd-dvt-con{
    grid-template-columns: repeat(1, 1fr);

  }
  .backOffice .content {
    height: auto;
  }
  .mobile-content .header-action-con {
    display: block;
  }
  header .header-action-con {
    display: none;
  }
  .mobile-content .header-action-con {
    background-color: #252a32;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 100px;
    padding: 6px 8px;
    /* margin-left:auto; */
    margin-top: 16px;
    max-width: max-content;
    justify-content: center;
    margin-left: 10px;
  }
  .mobile-content .header-action-con .clipboardcheck-con {
    background-color: #303641;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }
  .mobile-content .header-action-con .handban-con {
    background-color: #303641;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: relative;
  }
  .mobile-content .header-action-con .exclamation-con {
    background-color: #303641;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: relative;
  }
  .mobile-content .header-action-con svg {
    color: #9fa5af;
    font-size: 20px;
    cursor: pointer;
  }
}
@media (max-width: 480px) {
  header .profile-con .showProfile-con {
    min-width: 280px;
  }
  header .profile-con .showProfile-con .crypton-balance-con .one p {
    font-size: 13px;
  }
}
