@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.css-cveggr-MuiListItemIcon-root {
  min-width: 0 !important ;
}
.css-1f8bwsm{
  min-width: 0px !important;
}
body {
  background-color: #343c48;
}
