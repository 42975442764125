/* HEADER CSS */
header {
  width: calc(100% - 250px);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #303641;
  padding: 0 20px;
  border-bottom: 1px solid #000000;
  position: fixed;
  top: 0;
  gap: 60px;
}
header .wrap {
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
  justify-content: end;
  flex: 1;
}
header .url-con {
  margin-left: 50px;
  /* width: 700px; */
  position: relative;
  width: 100%;
  flex: 2;
}
header .url-con input {
  outline: none;
  background: none;
  border: none;
  border: 1px solid #7b7b7c;
  border-radius: 100px;
  padding: 12px 6px;
  width: 100%;
  padding-inline-start: 45px;
  font-size: 18px;
  color: #fff;
  position: relative;
  &::placeholder {
    color: #d86dc6;
  }
}
header .url-con .url-img {
  position: absolute;
  width: 30px;
  left: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
header .url-con .copy-img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 20px;
  color: #b38fe4;
}

header .search-con {
  position: relative;
}
header .search-con input {
  outline: none;
  background: none;
  border: none;
  border: 1px solid #4a5465;
  border-radius: 100px;
  padding: 8px 6px;
  width: 100%;
  padding-inline-start: 45px;
  font-size: 18px;
  position: relative;
  color: #fff;
  &::placeholder {
    color: #d86dc6;
  }
}
header .search-con .search {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  cursor: pointer;
  color: #707987;
  z-index: 1;
}
header .header-action-con {
  background-color: #252a32;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  padding: 6px 8px;
}
header .header-action-con svg {
  color: #9fa5af;
  font-size: 20px;
  cursor: pointer;
}

header .header-action-con .clipboardcheck-con {
  background-color: #303641;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
header .header-action-con .handban-con {
  background-color: #303641;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: relative;
}
header .header-action-con .handban-con span {
  position: absolute;
  background-color: #3eaad1;
  width: 14px;
  height: 14px;
  color: #fff;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 20px;
  padding-top: 3px;
}
header .header-action-con .exclamation-con {
  background-color: #303641;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: relative;
}
header .header-action-con .exclamation-con span {
  position: absolute;
  background-color: #da6044;
  width: 14px;
  height: 14px;
  color: #fff;
  right: 2px;
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 20px;
  padding-top: 3px;
}
header .profile-con {
  position: relative;
}
header .profile-con .box {
  border-radius: 100px;
  width: 48px;
  height: 48px;
  background-color: #3eaad1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
header .profile-con .showProfile-con {
  position: absolute;
  top: 55px;
  right: 0;
  height: auto;
  border-radius: 28px;
  overflow: hidden;
  min-width: 335px;
  z-index: 1000;
  /* padding: 25px; */
  /* background: #24252e; */
}
header .profile-con .showProfile-con .one {
  background: #24252e;
  padding: 14px 14px 8px 14px;
}
header .profile-con .showProfile-con .two {
  /* background: #000 !important; */
  background: #303641;
  padding: 14px 14px 18px 14px;
}
header .profile-con .showProfile-con .two .exclusive-offer {
  display: flex;
  justify-content: center;
}
header .profile-con .showProfile-con .two .exclusive-offer p {
  background-color: #000;
  color: #fff;
  border: 1px solid #ffffff80;
  font-size: 11px;
  font-family: poppins, sans-serif;
  margin-top: 30px;
  padding: 3px 8px;
  border-radius: 20px;
  cursor: pointer;
  transform: translateY(-24px);
}
header .profile-con .showProfile-con .two .contentt {
  text-align: center;
}
header .profile-con .showProfile-con .two .contentt h3 {
  color: #fff;
  font-family: poppins, sans-serif;
  letter-spacing: 0.5px;
  font-size: 18px;
  margin-bottom: 8px;
}
header .profile-con .showProfile-con .two .contentt p {
  color: #ffffff80;
  font-family: poppins, sans-serif;
  font-size: 14px;
  margin-bottom: 16px;
}
header .profile-con .showProfile-con .two .btnwrapper {
  width: 90%;
  margin-inline: auto;
}
header .profile-con .showProfile-con .two .btnwrapper button {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #f9e727;
  color: #000000;
  font-size: 16px;
  font-family: poppins, sans-serif;
  padding: 12px 16px;
  cursor: pointer;
  font-weight: 700;
}
header .profile-con .showProfile-con .one .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
header .profile-con .showProfile-con .one .top .logout-con {
  display: flex;
  gap: 10px;
  align-items: center;
}
header .profile-con .showProfile-con .one .top .logout-con svg {
  color: #ffffff80;
  cursor: pointer;
}
header .profile-con .showProfile-con .one .top p {
  color: #ffffff80;
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: 14px;
}
header .profile-con .showProfile-con .one .wallet-address-con {
  display: flex;
  justify-content: start;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ffffff80;
  align-items: center;
}
header .profile-con .showProfile-con .one .wallet-address-con img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 100px;
}
header .profile-con .showProfile-con .one .wallet-address-con p {
  color: #fff;
  font-size: 22px;
  font-family: "poppins", sans-serif;
  margin: 0;
}
header .profile-con .showProfile-con .one .wallet-address-con h3 {
  color: #fff;
  font-size: 15px;
  font-family: "poppins", sans-serif;
  margin: 10px;
}

header .profile-con .showProfile-con .one .wallet-address-con svg {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}
header .profile-con .showProfile-con .crypton-balance-con {
  margin-top: 24px;
}
header .profile-con .showProfile-con .crypton-balance-con .main-wrap {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 24px; */
}
header .profile-con .showProfile-con .crypton-balance-con .main-wrap .one {
}
header .profile-con .showProfile-con .crypton-balance-con .one p {
  color: #ffffff80;
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: 16px;
}
header .profile-con .showProfile-con .crypton-balance-con .one .wrap {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: start;
}
header
  .profile-con
  .showProfile-con
  .crypton-balance-con
  .main-wrap
  .one
  .wrap
  svg {
  /* font-size: 20px; */
}
header .profile-con .showProfile-con .crypton-balance-con .one .wrap h2 {
  color: #fff;
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: 20px;
}
header .profile-con .showProfile-con .two {
}

header .profile-con .box h3 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
}
/* SIDEBAR CSS */

.main-sidebar {
 
}

.sidebar-wrappp{}
/* Custom scrollbar styles */
.sidebar-wrappp::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.sidebar-wrappp::-webkit-scrollbar-thumb {
  background-color: #307fe2; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Round the scrollbar thumb */
}

.sidebar-wrappp::-webkit-scrollbar-track {
  background-color: #051a2e; /* Background color of the scrollbar track */
}

.sidebar-wrappp::-webkit-scrollbar-thumb:hover {
  background-color: #0a335c; /* Change color when hovering over the scrollbar thumb */
}

.main-sidebar .sidebar-profile {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 8px 16px 5px;
  margin: 0 0 0.5rem 0;
  overflow: hidden;
}
  a{
  text-decoration: none;
  color: #fff;
  &:hover{
    color: #fff;
  }
}
.main-sidebar .sidebar-profile p {
  color: #95a0b1;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
}
.main-sidebar .sidebar-profile h6 {
  color: #95a0b1;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.main-sidebar .sidebar-links-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
}
.main-sidebar .sidebar-links-con .sidebar-link {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  border-radius: 0 100px 100px 0;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  cursor: pointer;
}
.main-sidebar .sidebar-links-con .sidebar-link h3 {
  font-size: 22px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.sidebar-link:hover {
  background-color: #0d7fa8;
  border-radius: 0 100px 100px 0;
  border-top-right-radius: 100%;
}

.sidebar-link.active {
  background-color: #0d7fa8;
  border-radius: 0 100px 100px 0;
}

.sidebar-link span {
  margin-left: 10px;
}

/*  */
.backOffice {
  background-color: #343c48;
}
.backOffice .content {
  background-color: #343c48;
  height: 100vh;
}
.backOffice .content .breadcrum {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
}
.backOffice .content .breadcrum svg.home {
  cursor: pointer;
  font-size: 22px;
  color: #0d7fa8;
}
.backOffice .content .breadcrum p {
  color: #fff;
  font-family: poppins;
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 0;
}
.backOffice .content .breadcrum p svg {
  color: #fff;
  font-size: 20px;
}

.backOffice .content .card-con {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin: 0 auto;
}

.backOffice .content .card-con a {
  text-decoration: none;
}
.backOffice .content .card-con .cardd {
  border-radius: 12px;
  height: 135px;
  background-color: #303641;
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.backOffice .content .card-con .cardd .top {
}
.backOffice .content .card-con .cardd .top svg {
  font-size: 35px;
}
.backOffice .content .card-con .cardd .bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: flex-end;
}
.backOffice .content .card-con .cardd .bottom h3 {
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.backOffice .content .card-con .cardd .bottom p {
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0rem;
}

/* TABLE CSS */
/* styles.css */
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-top: 50px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  border-collapse: collapse;
  margin-top: 50px;
}
table thead {
  background-color: #1e90ff;
  color: #ffffff;
}
table thead th {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #444851;
  word-wrap: break-word;
}

th,
td {
  padding: 15px;
  text-align: left;
  color: #e0e0e0;
  font-family: "poppins", sans-serif;
  word-wrap: break-word; /* Break words onto a new line if they are too long */
  white-space: normal; /* Allow text to wrap onto multiple lines */
}

th {
  /* background-color: #f4f4f4; */
  font-family: "poppins", sans-serif;
}

tr:nth-child(even) {
  background-color: #2c2f36;
  color: #fff;
}
.mobile-content {
  display: none;
}

/* HOME */
.home {
}
.home-wrap {
  background-image: url(/public/assets/images/home-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-wrap .cardd {
  background-color: #0a1936;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 #0000001a;
  max-width: 400px;
  margin-inline: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-wrap .cardd img {
  width: 140px;
  margin-bottom: 12px;
}
.home-wrap .cardd h1 {
  font-family: "poppins", sans-serif;
  font-size: 25px;
  margin-bottom: 0px;
  text-align: center;
  color: #fff;
  line-height: 30px;
}
.home-wrap .cardd p {
  font-family: "poppins", sans-serif;
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
  color: #fff;
}
.home-wrap .cardd .btn-wrapper {
  text-align: center;
}
.home-wrap .cardd .btn-wrapper button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.home-wrap .cardd .btn-wrapper button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* BUT DVT */
.main-cardd-buyDvt {
  background-color: #202c38;
  border-radius: 12px;
  padding: 16px;
}
.main-cardd-buyDvt .top {
  margin-bottom: 24px;
}
.main-cardd-buyDvt .top h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  font-family: "poppins", sans-serif;
}
.cardd-dvt-con {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}
.cardd-dvt-con .cardd-dvt {
  background-color: #2b3846;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.cardd-dvt-con .cardd-dvt .plan-heading {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  font-family: poppin, sans-serif;
}
.cardd-dvt-con .cardd-dvt .wrap-content-dvt {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}
.cardd-dvt-con .cardd-dvt .wrap-content-dvt h2 {
  margin: 0;
  color: #fff;
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 18px;
}
.cardd-dvt-con .cardd-dvt .wrap-content-dvt p {
  margin: 0;
  color: #fff;
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.cardd-dvt-con .cardd-dvt button {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.cardd-dvt-con .cardd-dvt button:hover {
  background-color: #218838;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.card-link + .card-link {
  margin-left: 0;
}

/* .loader {
  width: 64px !important;
  height: 48px !important;
  position: relative;
  animation: split 1s ease-in infinite alternate;
}
.loader::before , .loader::after {
  content: '';
  position: absolute;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  left: 0;
  top: 0;
  transform: translateX(-10px);
  background: #FF3D00;
  opacity: 0.75;
  backdrop-filter: blur(20px);
}

.loader::after {
  left: auto;
  right: 0;
  background: #FFF;
  transform: translateX(10px);
}

@keyframes split {
 0% , 25%{ width: 64px }
 100%{ width: 148px }
} */

/* From Uiverse.io by Praashoo7 */
.loader {
  display: flex;
  align-items: center;
  margin-left: -0.65em;
  height: 100vh;
  justify-content: center;
}

.load1 {
  position: absolute;
  content: "";
  margin-right: 1em;
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
}

.load2 {
  position: absolute;
  margin-right: 1em;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  animation: 2s flow2 infinite;
}

.load3 {
  position: absolute;
  margin-right: 1em;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  animation: 2s flow3 infinite;
}

.load4 {
  position: absolute;
  margin-right: 1em;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  animation: 2s flow4 infinite;
}

.load5 {
  position: absolute;
  margin-right: 1em;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  animation: 2s flow5 infinite;
}

.load6 {
  position: absolute;
  margin-right: 1em;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  animation: 6s flow6 infinite;
}

.load7 {
  position: absolute;
  margin-right: 1em;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  animation: 6s flow7 infinite;
}

.load8 {
  position: absolute;
  margin-right: 1em;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  animation: 6s flow8 infinite;
}

.load9 {
  position: absolute;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  background-color: white;
  animation: 6s flow9 infinite;
}

@keyframes flow2 {
  50% {
    transform: translateX(3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateX(0em);
  }
}

@keyframes flow3 {
  50% {
    transform: translateX(-3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateX(0em);
  }
}

@keyframes flow4 {
  50% {
    transform: translateY(3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateY(0em);
  }
}

@keyframes flow5 {
  50% {
    transform: translateY(-3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateY(0em);
  }
}

@keyframes flow6 {
  30% {
    transform: translateX(3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  60% {
    transform: translateX(3em) translateY(-3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateX(0em) translateY(0em);
  }
}

@keyframes flow7 {
  30% {
    transform: translateX(-3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  60% {
    transform: translateX(-3em) translateY(3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateX(0em) translateY(0em);
  }
}

@keyframes flow8 {
  30% {
    transform: translateY(3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  60% {
    transform: translateY(3em) translateX(3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateX(0em) translateY(0em);
  }
}

@keyframes flow9 {
  30% {
    transform: translateY(-3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  60% {
    transform: translateY(-3em) translateX(-3em);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateX(0em) translateY(0em);
  }
}

/* IMAGE UPLOAD */
.img-upload-comp {
  position: relative;
  /* width: 200px;  */
  display: flex;
  align-items: center;
  gap: 40px;
}

.img-upload-comp p {
  margin-bottom: 10px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
}

.img-upload-comp .img-wrapper {
  position: relative;
  border-radius: 0.5rem;
  border: 1px solid #bfbfbf;
  display: inline-block;
  padding: 0.5rem 0.4rem;
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 50px;
  margin-inline: auto;
}
.img-upload-comp .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-upload-comp.uploaded-img {
  width: 100%;
  height: 85px;
  border-radius: 8px;
  object-fit: contain;
}

.img-upload-comp .icon-overlay {
  position: absolute;
  top: -20px;
  right: -20px;
  display: flex;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
}
.img-upload-comp.icon-overlay label svg {
  color: #fff;
}
.img-upload-comp .icon-overlay label h3 {
  margin: 0;
  color: #fff;
  font-size: 16px;
  line-height: 0;
  padding-bottom: 4px;
}
.img-upload-comp .upload-icon {
  background: #000;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 18px;
  height: 18px;
}

.img-upload-comp .upload-icon input {
  display: none;
}

.img-upload-comp .delete-icon {
  outline: none;
  border: none;
  background: none;
  font-family: "Nunito Sans", sans-serif;
  border: 1px solid #e0201c;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  min-width: 11.75rem;
  color: #e0201c;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: none;
  &:hover {
    background-color: #e0201c;
    color: #fff;
  }
}

h1.buy-ico {
  color: #fff;
  font-family: poppins, sans-serif;
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #444851;
  padding-bottom: 8px;
}
.finance-buy-send-card {
  max-width: 400px;
  margin-top: 50px;
  margin-inline: auto;
}
.finance-buy-send-card .send-recieved-con {
}
.finance-buy-send-card .send-recieved-con h2 {
  color: #fff;
  font-family: poppins, sans-serif;
  font-size: 18px;
  margin-bottom: 3px;
  margin-left: 4px;
}
.finance-buy-send-card .send-recieved-con .text-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  background-color: #2f3f50;
  padding: 4px 12px;
  border-radius: 6px;
}
.finance-buy-send-card .send-recieved-con .text-wrap h3 {
  color: #fff;
  font-family: poppins, sans-serif;
  font-size: 16px;
  margin: 0;
}
.finance-buy-send-card .send-recieved-con .text-wrap img {
  object-fit: contain;
}
.finance-buy-send-card .input-con {
  margin-top: 24px;
  margin-bottom: 12px;
}
.finance-buy-send-card .input-con h2 {
  color: #fff;
  font-family: poppins, sans-serif;
  font-size: 18px;
  margin-bottom: 3px;
  margin-left: 4px;
}
.finance-buy-send-card .input-con input {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 12px 8px;
  color: #fff;
  font-family: poppins, sans-serif;
  font-size: 16px;
  outline: none;
}
.finance-buy-send-card .input-con .wrap {
  border-radius: 6px;

  display: flex;
  align-items: center;
  border: 1px solid #fff;
}
.finance-buy-send-card .input-con .wrap span {
  border-right: 1px solid #fff;
  height: 48px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 14px;
  font-family: poppins, sans-serif;
  padding: 4px;
}
.finance-buy-send-card .price-con {
  background-color: #2f3f50;
  padding: 6px 12px;
  border-radius: 6px;
  margin-bottom: 30px;
}
.finance-buy-send-card .price-con h2{
  color: #fff;
  font-family: poppins, sans-serif;
  font-size: 18px;
  margin-bottom: 3px;
  margin-left: 4px;
  color: #3a84ff;
}
.finance-buy-send-card .price-con p{
  color: #fff;
  font-family: poppins, sans-serif;
  font-size: 18px;
  margin-bottom: 3px;
  margin-left: 4px;
  color: #219653;
}
.finance-buy-send-card .btn-wrap{
  text-align: center;
}
.finance-buy-send-card .btn-wrap button{
  background-color: #0060ff;
  border-radius: 6px;
  padding: 12px 16px;
  outline: none;
  border: none;
  width: 100%;
  color: #fff;
  font-family: poppins,sans-serif;
  font-size: 16px;
}


/* CONFIRMATION PAGE */
.confirmation-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;
  padding: 20px;
}

.confirmation-page p {
  margin: 10px 0;
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;

}

.confirmation-page h1 {
  margin: 10px 0;
  /* font-size: 1.2rem; */
  color: #fd3146;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.btn-wrap {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

/* Button styles */
button.sure {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 120px;
  transition: background-color 0.3s ease;
}

button.sure:first-child {
  background-color: #28a745; 
  color: #fff;
}

button.sure:first-child:hover {
  background-color: #218838;
}

button.sure:last-child {
  background-color: #dc3545; 
  color: #fff;
}

button.sure:last-child:hover {
  background-color: #c82333;
}

.captcha-wrap {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}


/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 400; 
} */
.sidebarWidthDeafult{
  margin-left: 250px;
}


.main-sidebar {
  position: relative;
}

.sidebar-wrap {
  /* Styles for the sidebar */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 400;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .sidebar-wrap {
    /* Styles for desktop */
  }

  .overlay {
    display: none; /* Hide overlay on desktop */
  }
}
