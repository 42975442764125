/* .updateProfileModal {
}
.updateProfileModal .modal-lg {
  max-width: 320px;
  margin-inline: auto;
}
.updateProfileModal .modal-content {
  background: linear-gradient(135deg, #2b3038, #59606d);
  color: #fff;
}
.updateProfileModal .modal-header {
  padding: 16px 8px 8px 8px;
}
.updateProfileModal .modal-header h3 {
  font-size: 20px;
  font-family: poppins, sans-serif;
}
.updateProfileModal .modal-body {
  padding: 8px;
}
.updateProfileModal .modal-body form {
  margin-top: 24px;
}
.updateProfileModal .modal-body form .form-groug {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.updateProfileModal .modal-body form .form-groug label {
  font-size: 14px;
  color: #e0e0e0;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-family: poppins, sans-serif;
}
.updateProfileModal .modal-body form .form-groug input {
  padding: 0.75rem 1rem;
  font-size: 14px;
  border: 1px solid #59606d;
  border-radius: 5px;
  background-color: #2e3440;
  color: #ffffff;
  outline: none;
  transition: border-color 0.3s ease;

  &::placeholder {
    color: #7a828e;
  }
}
.updateProfileModal .modal-body form .form-groug input:focus {
  border-color: #4c566a;
}
.updateProfileModal .modal-body form  .btn-wrapper {
  display: flex;
  justify-content: center; 
  margin-top: 1.5rem; 
}

.updateProfileModal .modal-body form  .btn-wrapper button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: hsl(0, 0%, 100%);   
  background: linear-gradient(
    135deg,
    #59606d,
    #343c48
  ); 
  border: none;
  border: 1px solid #59606d;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.updateProfileModal .modal-body form  .btn-wrapper button:hover {
  background: linear-gradient(
    135deg,
    #4a4f5a,
    #2e343b
  ); 
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); 
} */

.updateProfileModal {
  /* Optional: Add any global styles for the modal here */
}

.updateProfileModal .modal-lg {
  max-width: 320px;
  margin-inline: auto;
}

.updateProfileModal .modal-content {
  background: linear-gradient(135deg, #2b3038, #59606d);
  color: #fff;
}

.updateProfileModal .modal-header {
  padding: 16px 8px 8px 8px;
}

.updateProfileModal .modal-header h3 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.updateProfileModal .modal-body {
  padding: 8px;
}

.updateProfileModal .modal-body form {
  margin-top: 24px;
}

.updateProfileModal .modal-body form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.updateProfileModal .modal-body form .form-group label {
  font-size: 14px;
  color: #e0e0e0;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
}

.updateProfileModal .modal-body form .form-group input,
.updateProfileModal .modal-body form .form-group .PhoneInput {
  padding: 0.75rem 1rem;
  font-size: 14px;
  border: 1px solid #59606d;
  border-radius: 5px;
  background-color: #2e3440;
  color: #ffffff;
  outline: none;
  transition: border-color 0.3s ease;
}

.updateProfileModal .modal-body form .form-group input::placeholder,
.updateProfileModal
  .modal-body
  form
  .form-group
  .PhoneInput
  input::placeholder {
  color: #7a828e;
}

.updateProfileModal .modal-body form .form-group input:focus,
.updateProfileModal .modal-body form .form-group .PhoneInput input:focus {
  border-color: #4c566a;
}

.updateProfileModal .modal-body form .btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.updateProfileModal .modal-body form .btn-wrapper button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: hsl(0, 0%, 100%);
  background: linear-gradient(135deg, #59606d, #343c48);
  border: none;
  border: 1px solid #59606d;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.updateProfileModal .modal-body form .btn-wrapper button:hover {
  background: linear-gradient(135deg, #4a4f5a, #2e343b);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

/* Styles for PhoneInput component */
.updateProfileModal .modal-body form .PhoneInput {
  height: 3rem; /* Ensure consistent height */
  width: 100%; /* Full width to match other inputs */
}

/* CONFIRMATION MODAL */

.confirmationModal .modal-lg {
  max-width: 420px;
  margin-inline: auto;
}

.confirmationModal .modal-content {
  background: linear-gradient(135deg, #2b3038, #59606d);
  color: #fff;
}

.confirmationModal .modal-header {
  padding: 16px 8px 8px 8px;
}

.confirmationModal .modal-body {
}

.confirmationModal .modal-body p {
  color: #fff;
  margin-bottom: 12px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  text-align: center;
}

.confirmationModal .modal-body h4 {
  color: #fff;
  margin-bottom: 24px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  text-align: center;
}

.confirmationModal .modal-body .btn-wrap {
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 10px;
  margin-top: 20px;
}

.confirmationModal .modal-body .btn-wrap button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 120px;
  font-family: Poppins, sans-serif;
}

.confirmationModal .modal-body .btn-wrap button.cancel {
  background-color: #f0f0f0;
  color: #333;
}

.confirmationModal .modal-body .btn-wrap button.cancel:hover {
  background-color: #e0e0e0;
}

.confirmationModal .modal-body .btn-wrap button.sure {
  background-color: #007bff; 
  color: white;
}

.confirmationModal .modal-body .btn-wrap button.sure:hover {
  background-color: #0056b3;
}

@media (max-width: 450px) {
  .confirmationModal .modal-lg {
    max-width: 90%;
  }
}