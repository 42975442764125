/* Main container */
.admin-airdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #343c48;
    padding: 20px; /* Added padding for small screens */
}

/* Card styling */
.admin-airdrop-card {
    background-color: #303641;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

/* Title styling */
.airdrop-title {
    color: #fff;
    margin-bottom: 24px;
}

/* Input styling */
.airdrop-file-input,
.airdrop-amount-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: none;
    border-radius: 8px;
    background-color: #424854;
    color: #fff;
}

/* Button container for centering */
.airdrop-button-container {
    display: flex;
    justify-content: center;
}

/* Button styling */
.airdrop-submit-button {
    padding: 12px 20px;
    background-color: #0d7fa8;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: auto; /* Auto width to shrink the button */
    transition: background-color 0.3s ease;
}

.airdrop-submit-button:hover {
    background-color: #085f78;
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
    .admin-airdrop-card {
        padding: 20px; /* Reduce padding for smaller screens */
    }

    .airdrop-file-input,
    .airdrop-amount-input {
        padding: 10px; /* Adjust padding for input fields */
        font-size: 14px; /* Slightly reduce font size for smaller screens */
    }

    .airdrop-submit-button {
        padding: 10px; /* Adjust button padding */
        font-size: 14px; /* Adjust button font size */
    }
}

@media (max-width: 480px) {
    .admin-airdrop-card {
        padding: 15px; /* Further reduce padding for very small screens */
    }

    .airdrop-file-input,
    .airdrop-amount-input {
        padding: 8px; /* Further reduce padding for input fields */
        font-size: 12px; /* Adjust font size for very small screens */
    }
}